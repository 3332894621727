import React from 'react';

const Checkbox = props => {
  return (
    <div className="form-group">
      <label className={`form-checkbox ${Boolean(props.hasError) ? 'is-error' : ''}`}>
        <input
          type="checkbox"
          name={props.name}
          defaultChecked={props.defaultChecked}
          onChange={props.onChange}
          onClick={props.onClick}
        />
        <i className="form-icon" /> {props.label}
      </label>
      <p className="form-input-hint">{Boolean(props.hasError) && props.hint}</p>
    </div>
  );
};

export { Checkbox };
