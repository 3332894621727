import React, { useState } from 'react';
import { IconTrue } from '../Icons/IconTrue';
import { IconFalse } from '../Icons/IconFalse';
import { ButtonEdit } from '../Buttons/ButtonEdit';
import { ButtonDelete } from '../Buttons/ButtonDelete';
import { ButtonSave } from '../Buttons/ButtonSave';
import { Input } from '../Form/Input';
import { Checkbox } from '../Form/Checkbox';

const Item = props => {
  const [item, setItem] = useState(props.item);
  const [isEditing, setIsEditing] = useState(false);

  /**
   *
   * @param {import('react').FormEvent} event
   */
  const handleSubmit = event => {
    event.preventDefault();

    if (!item.domain || !item.cname) return;

    setIsEditing(false);

    props.updateItem(item.id, item);
  };

  /**
   *
   * @param {import('react').ChangeEvent<HTMLInputElement>|import('react').ChangeEvent<HTMLSelectElement>} event
   */
  const handleInputChange = event => {
    const { name, value } = event.target;

    let setValue = value;

    if (name === 'forced') {
      setValue = !item.forced;
    }

    setItem({ ...item, [name]: setValue });
  };

  return (
    <>
      {isEditing ? (
        <tr>
          <td colSpan={5}>
            <form method="post" onSubmit={handleSubmit}>
              <Input
                name="domain"
                label="Domain"
                value={item.domain}
                handleChange={handleInputChange}
              />

              <Input
                name="cname"
                label="CName"
                value={item.cname}
                handleChange={handleInputChange}
              />

              <Input
                name="controlLabel"
                label="Namesgruppe"
                value={item.controlLabel}
                handleChange={handleInputChange}
              />

              <Checkbox
                name="forced"
                label="Aktiv?"
                defaultChecked={item.forced}
                onClick={handleInputChange}
              />

              <ButtonSave showLabel={true} />
            </form>
          </td>
        </tr>
      ) : (
        <tr>
          <td>{item.domain}</td>
          <td>{item.cname}</td>
          <td>{item.controlLabel}</td>
          <td>{item.forced ? <IconTrue /> : <IconFalse />}</td>
          <td style={{ textAlign: `right` }}>
            <ButtonEdit
              onClick={event => {
                event.preventDefault();
                setIsEditing(true);
              }}
            />
            <ButtonDelete
              onClick={() => window.confirm('Sind Sie sicher?') && props.deleteItem(item.id)}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export { Item };
