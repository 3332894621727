import React from 'react';

import { Item } from './Item';

const SafeSearchTable = props => (
  <>
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th>Domain</th>
          <th>CNAME</th>
          <th>Namensgruppe</th>
          <th>aktiv</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {props.items && props.items.length > 0
          ? props.items.map(item => (
              <Item
                item={item}
                key={item.id}
                updateItem={props.updateItem}
                deleteItem={props.deleteItem}
              />
            ))
          : null}
      </tbody>
    </table>
  </>
);

export { SafeSearchTable };
